.header {
  align-items: center;
  background-color: var(--primary);
  padding-top: 20px;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: 0.25s linear;
}

.headerLogo {
  grid-column: 1 / span 2;
  width: 71%;
}

.headerMenu {
  grid-column: 3 / span 1;
  color: white !important;
  fill: white !important;
  width: 50%;
  justify-self: end;
}

.headerNav {
  display: none;
}

.header .button {
  display: none;
}

@media screen and (min-width: 430px) {
  .headerMenu {
    grid-column-start: 6;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .headerLogo {
    grid-column-start: 2;
  }

  .headerMenu {
    display: none;
  }

  .headerNav {
    display: block;
    grid-column: 4 / span 6;
    font: var(--nav);
    color: var(--other);
  }

  .headerNav ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(7, auto);
    align-items: center;
    justify-content: space-around;
  }

  .headerNav li {
    cursor: pointer;
  }

  .header .button {
    display: block;
    grid-column: 10 / span 2;
  }
}
