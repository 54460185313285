.button {
  padding: 15px 0;
  font: var(--normal);
  border: 1px solid var(--other);
  border-radius: 50px;
  background-color: var(--primary);
  color: var(--other);
  cursor: pointer;
}

.button:hover {
  background-color: var(--other);
  color: var(--primary);
  transition: 0.25s linear;
}
